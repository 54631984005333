.conteinerGeralSobreNos {
    display: flex;
    flex-direction: column;
    align-items: center;

    background-image: url('../assets/paredemadeira.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    h1 {
        background-image: linear-gradient(to left, var(--verde), var(--amarelo), var(--vermelho));
        padding: 0.5rem;
        width: 60%;
        margin-top: 1rem;
        border-radius: 0.8rem;
        color: var(--branco);
        font-weight: bold;
        text-align: center;
    }

    .conteinerPrimeiroSobreNos {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        .divPrimeiroSobreNos {
            width: 70%;

            p {
                font-size: 2.5rem;
                text-align: justify;
                font-weight: bold;
                margin-left: 1.5rem;
            }
        }

        .divSegundoSobreNos {
            width: 30%;

            img {
                width: 100%;
                padding: 1rem;
                border-radius: 1.5rem;
            }

        }

    }

    .divTerceiroSobreNos {
        width: 100%;

        p {
            font-size: 2.5rem;
            text-align: justify;
            font-weight: bold;
            margin-left: 1.5rem;
            margin-right: 1.5rem;
        }
    }
}

@media only screen and (max-width: 520px) {
    .conteinerGeralSobreNos {

        .conteinerPrimeiroSobreNos {

            flex-direction: column;

            .divPrimeiroSobreNos {
                width: 100%;

                p {
                    font-size: 1.5rem;
                    margin-top: 0.5rem;
                    margin-left: 1rem;
                    margin-right: 1rem;
                }
            }

            .divSegundoSobreNos {
                width: 100%;

                img {
                    width: 100%;
                    padding: 1rem;
                }

            }
        }

        .divTerceiroSobreNos {

            p {
                font-size: 1.5rem;
                margin-top: 0.5rem;
                margin-left: 1rem;
                margin-right: 1rem;
            }
        }
    }

}