.conteinerGeralInicio {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    background-image: url('../assets/paredemadeira.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    width: 100%;

    .contCarrossel {
        margin: 1rem;
        position: relative;
        width: 70%;

        display: -webkit-box;
        display: flex;

        width: 860px;
        height: 590px;
        -webkit-box-pack: initial;

        img {
            width: 960px;
            height: 590px;
            margin-top: 1px;
            padding: auto;
            object-fit: cover;
            border-radius: 2rem;
        }
    }

    .contReceitas {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 30%;

        h1 {
            background-image: linear-gradient(to left, var(--verde), var(--amarelo), var(--vermelho));
            padding: 0.5rem;
            width: 100%;
            margin-top: 2rem;
            border-radius: 0.8rem;
            color: var(--branco);
            font-weight: bold;
            text-align: center;
        }

        .mapProdutos {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;


            .cardProdutos {
                padding: 0.5rem;
                border-radius: 5px;
                box-shadow: 7px 7px 13px 0px #ffffff;
                padding: 30px;
                margin: 20px;
                width: 150px;
                height: 300px;
                transition: all 0.5s ease-out;

                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                img {
                    padding: 0.5rem;
                    height: 10rem;
                    mix-blend-mode: multiply; //remover Fundo da Imagem
                }

                p {
                    font-size: 1.5rem;
                    font-weight: bold;
                }
            }
        }
    }
}

@media only screen and (max-width: 992px) {

    .conteinerGeralInicio {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;


        .contCarrossel {
            width: 100%;
            height: 590px;
            padding: 0.5rem;
            margin: 0.5rem;

            img {
                width: 100%;
                height: 590px;
            }
        }

        .contReceitas {
            width: 90%;

            .mapProdutos {

                .cardProdutos {
                    width: 80%;
                    height: 200px;

                    img {
                        height: 10rem;
                    }

                    p {}
                }
            }

        }
    }
}

@media only screen and (max-width: 1024px) {

    .conteinerGeralInicio {
        flex-direction: column;
        justify-content: center;
        width: 100%;


        .contCarrossel {

            img {}
        }

        .contReceitas {
            width: 90%;

            .mapProdutos {

                .cardProdutos {

                    img {}

                    p {}
                }
            }
        }
    }
}