.conteinerGeralRodape {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(to right, var(--verde), var(--amarelo), var(--vermelho));
    width: 100%;
    height: 3rem;
    
    p{
        text-align: center;
        margin-top: 1rem;
        padding: 1rem;
        color: var(--branco);
        font-weight: bold;
    }
}

@media only screen and (max-width: 992px) {
    
    .conteinerGeralRodape {
        
    }
}