.conteinerGeralRodapeN {
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('../../assets/tijolinho.jpg');
    height: 6rem;   
}

@media only screen and (max-width: 991px) {

    .conteinerGeralCabecalho {
        justify-content: flex-start;
        background-image: url('../../assets/tijolinhoOpaco.png');       
           
    }      
}