.conteinerGeralCabecalho {
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('../../assets/tijolinho.jpg');   

    a {
        text-align: center;
        color: var(--branco);
        font-size: 2rem;
        font-weight: bold;
        border: 0.2rem solid var(--branco);
        border-radius: 0.5rem;
        padding: 0.5rem;
        margin-top: 0.5rem;
        margin-left: 1rem;
        margin-right: 0.2rem; 
    }   
}

@media only screen and (max-width: 991px) {

    .conteinerGeralCabecalho {
        justify-content: flex-start;
        background-image: url('../../assets/tijolinhoOpaco.png');

        
        
        a{            
            width: 100%;
            color: var(--preto);
            border: 0.2rem solid var(--preto);
        }        
    }      
}