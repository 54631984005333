.conteinerGeralLojaVistual{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;

    background-image: url('../assets/paredemadeira.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    h1 {
        background-image: linear-gradient(to left, var(--verde), var(--amarelo), var(--vermelho));       
        padding: 0.5rem;
        width: 60%;
        margin-top: 1rem;
        border-radius: 0.8rem;
        color: var(--branco);
        font-weight: bold;
        text-align: center;
    }
    
    img{
        width: 50%;
    }
}

@media only screen and (max-width: 992px) {
   
    .conteinerGeralLojaVistual{
        img{
            width: 100%;
        }
    }
   
}
