.conteinerProdutosGeral {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url('../assets/paredemadeira.jpg');

    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
   

    h1 {
        background-image: linear-gradient(to left, var(--verde), var(--amarelo), var(--vermelho));
        padding: 0.5rem;
        width: 60%;
        margin-top: 1rem;
        border-radius: 0.8rem;
        color: var(--branco);
        font-weight: bold;
        text-align: center;
    }


    .conteinerImagensProdutos {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 2rem;

        p {
            text-align: center;
            font-size: 1.5rem;
            font-weight: bold;
            margin-top: 1.5rem;
            background-color: var(--p_cardprodutos);
            color: var(--branco);
            padding: 0.5rem;
            border-radius: 0.8rem;
        }

        .cardProdutos {
            padding: 0.5rem;
            border-radius: 0.3rem;
            box-shadow: 0.6rem 0.7rem 0.5rem 0.7rem var(--cardShadow);
            padding: 2rem;
            margin: 1rem;
            width: 20%;
            height: 22rem;
            transition: all 0.5s ease-out;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        img {
            margin-top: 0;
            height: 15rem;
            border-radius: 0.8rem;
            padding: 1rem;
        }

        .duplasImagensProdutos {
            display: flex;
            margin-top: 1rem;
            align-items: center;
            justify-content: space-around;
            width: 100%;

        }
    }
}

@media only screen and (max-width: 991px) {
    .conteinerProdutosGeral {

        .conteinerImagensProdutos {
            width: 100%;

            p{
                font-size: 1.2rem;
            }

            .cardProdutos {
                width: 100%;
                box-shadow: 0.5rem 0.5rem 0.5rem 0.5rem var(--cardShadow);
            }

            .duplasImagensProdutos {
                flex-direction: column;
            }
        }
    }
}