.conteinerGeralFaixaCabecalho {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-image: linear-gradient(to right, var(--verde), var(--amarelo), var(--vermelho));
    height: 6rem;
    width: 100%;

    .loguinhoEsquerda {
        display: flex;
        align-items: center;
        width: 20%;

        img {
            width: 23%;
            margin-left: 1rem;
        }
    }

    .nomeSG {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 70%;

        img {
            width: 40%;
            padding: 0.5rem;
        }
    }

    .redeSocial {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-left: 0.5rem;
        width: 15%;

        a {

            img {
                width: 90%;
                padding: 0.1rem;
            }
        }
    }
}

@media only screen and (max-width: 992px) {
    .conteinerGeralFaixaCabecalho {
        height: 4rem;


        .loguinhoEsquerda {


            img {
                width: 70%;
            }
        }

        .nomeSG {
            img {
                width: 100%;
            }
        }

        .redeSocial {
            width: 50%;
            margin-right: 0.2rem;

            a {

                img {
                    width: 100%;
                    padding: 0.3rem;
                }
            }
        }
    }
}